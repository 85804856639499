#infrastructure {
  max-width: 1400px;
  margin: 0 auto;
  h2 {
    font-family: 'Abel', sans-serif;
  }
  p {
    font-family: 'Roboto', sans-serif;
  }
}
#infrastructure-description {
  padding: 50px;
  p {
    padding: 50px;
  }
}

.infrastructure-section-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  &:nth-of-type(2) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%) !important;
  }
  &:nth-of-type(even) {
    background-color: #eee;
    z-index: 2;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
  }

  &:nth-of-type(odd) {
    justify-content: flex-end;
    z-index: 1;
  }
}

.infrastructure-section {
  max-width: 800px;
  padding: 50px;
  p {
    text-align: left;
  }
}
